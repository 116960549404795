@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {
  .home-container {
    @apply h-[100vh] flex flex-col
  }
  .home-main {
    @apply grow flex justify-center w-full px-5 flex flex-col items-center -mt-12
  }
  .home-header-container {
    @apply h-16 flex justify-between md:justify-end items-center gap-4 px-5
  }
  .home-headers {
    @apply flex gap-4
  }
  .home-header {
    @apply text-primary-darker text-[13px] hover:underline cursor-pointer
  }
  .google-logo {
    @apply w-[172px] md:w-[272px] mb-8
  }
  .home-buttons {
    @apply flex gap-2 text-black mt-8
  }
  .home-button {
    @apply h-9 px-4 bg-primary-lightgray text-sm rounded-md border border-primary-lightgray hover:border-primary-gray2 hover:shadow-c2
  }
  .menu-items {
    @apply flex items-center p-3 text-primary-dark cursor-pointer relative
  }
  .menu-item-icon {
    @apply hidden md:block mr-2
  }
  .menu-item-name {
    @apply text-sm
  }
  .selected-menu-item {
    @apply h-[3px] w-[calc(100%-20px)] absolute bg-primary-blue bottom-0 left-[10px]
  }
  .location-container {
    @apply flex py-[15px] px-[15px] md:px-[30px] border-b border-primary-gray
  }
  .location-header {
    @apply text-primary-dark1 text-[15px] leading-none
  }
  .search-result-header-container {
    @apply p-[15px] pb-0 md:pr-5 md:pl-20 md:pt-7 border-b border-primary-lightborder flex md:block flex-col items-center sticky top-0 bg-white
  }
  .search-result-header-navbar {
    @apply flex items-center justify-between w-full
  }
  .search-result-left-side {
    @apply flex items-center grow
  }
  .search-result-right-side {
    @apply flex ml-[-12px] mt-3
  }
  .search-result-google-logo {
    @apply hidden md:block w-[92px] mr-10
  }
  .search-input {
    @apply grow outline-0 text-primary-darker
  }
  .search-result-container {
    @apply flex flex-col min-h-[100vh]
  }
  .search-result-main {
    @apply grow
  }
  .footer-menu {
    @apply flex flex-col md:flex-row justify-between py-3 md:py-0 md:px-[15px] border-b border-primary-gray
  }
  .footer-side {
    @apply flex justify-center
  }
  .footer-menu-item {
    @apply text-primary-dark text-[12px] md:text-[14px] leading-none m-[10px] md:m-[15px] cursor-pointer
  }
  .profile-container {
    @apply flex gap-2
  }
  .profile {
    @apply h-10 w-10 relative flex justify-center items-center
  }
  .profile-dots {
    @apply rounded-full cursor-pointer hover:bg-primary-gray1
  }
  .profile-img {
    @apply h-full w-full object-cover rounded-full
  }
  .input-icons-container {
    @apply flex items-center gap-3
  }
  .input-icon {
    @apply h-6 w-6 cursor-pointer
  }
  .input-close-icon {
    @apply cursor-pointer text-primary-gray3
  }
  .search-box {
    @apply h-[46px] w-full md:w-[584px] flex items-center gap-3 px-4 border border-primary-gray rounded-3xl hover:shadow-c hover:border-transparent focus-within:shadow-c focus-within:border-transparent
  }
}